.card {
    padding: 10px;
    border-radius: 8px;
    background: rgb(168, 247, 221);
    /* color: aquamarine; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.card_header {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 0;
}

.content {
    border-top: 1px solid #939393;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}