.card {
    width: max-content;
    height: 220px;
    border: 1px solid #c2c2c2;
    /* padding: 2px; */
    border-radius: 15px;
    box-shadow: 0px 1px 5px #c2c2c2;
}

.card img {
    width: 15vw;
    border-radius: 15px;
    height: 150px;
}

.card p {
    padding: 5px;
}

.card:hover {
    transform: scale(1.05);
    transition: 220ms ease-in-out;
    box-shadow: 0px 1px 10px #000;
}

@media only screen and (max-width: 600px) {
    .card img {
        width: 80vw;
        /* height: 40vh; */
    }
}