.user {
    display: flex;
    gap: 100px;
    align-items: center;
    justify-content: space-between;
    /* width: 80vw; */
    border-radius: 50px;
    box-shadow: 0px 1px 10px #000;
    /* width: 40vw; */
    padding: 20px 40px;
    color: black;
    text-decoration: none;
}

.user img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}