body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input {
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
  background: #fcfcfc;
  border: 1px solid #b3b3b3;
}

textarea {
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  background: #fcfcfc;
  border: 1px solid #b3b3b3;
}

label {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: start;
  gap: 20px;
}

select {
  border: none;
  font-size: 16px;
  background: #eaeaea;
  padding: 5px 15px;
  border-radius: 8px;
  border: 1px solid #838383;
}

.row-item:hover {
  /* transform: scale(1.01); */
  /* transition: ease-in-out 330ms; */
  /* filter: brightness(50%); */
  background: #eeeeee;
}

.container {
  padding-top: 50px;
  margin-left: 11vw;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 15px;
}

.button {
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 3px 5px #c2c2c2;
  background: aquamarine;
  font-size: 16px;
  color: #333;
}

.cancel-button {
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 3px 5px #c2c2c2;
  background: transparent;
  font-size: 16px;
  color: red;
}

.button:hover {
  transform: scale(1.1);
  transition: 330ms ease-in-out;
  box-shadow: 0px 1px 10px rgb(51, 177, 135);
}

.cancel-button:hover {
  transform: scale(1.1);
  transition: 330ms ease-in-out;
  box-shadow: 0px 1px 10px red;
}

.input_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.grid-4 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
}

.abs {
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* padding: 100px; */
  z-index: 10;
}

.blur_container {
  width: 99.2vw;
  min-height: 88vh;
  height: 100vh;
  /* padding-top: 100px; */
  /* max-height: max-content; */
  backdrop-filter: blur(10px);
  position: absolute;
  background-color: #ffffff21;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.modal {
  /* margin: 50px; */
  padding: 50px;
  width: 40vw;
  height: max-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #939393;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: start;
  gap: 30px;
}

.two-line {
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loader-container {
  width: 89vw;
  height: 88vh;
  margin-left: 10vw;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  /* background: rgba(0, 0, 0, alpha); */
}

.loader::after,
.loader::before {
  content: '';
  width: 80px;
  height: 80px;
  border: 2px solid #c5c8ff;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  animation: rotation 2s ease-in-out infinite;
}

.loader::after {
  border-color: #3640ff;
  animation-delay: 1s;
}

.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .grid-4 {
    display: flex;
    flex-direction: column;
  }

  .modal {
    width: 90vw;
  }

  .two-line {
    width: 90vw;
  }
}