.container {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: max-content;
    position: absolute;
    left: 0;
    top: 0;
    color: aquamarine;
    background-color: #004024;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    /* padding: 5px; */
    border-radius: 80px;
    margin: 20px;
    width: 6vw;
}

.link {
    color: aliceblue;
    text-decoration: none;
    font-size: 16px;
    padding: 20px 0px;
    width: 6vw;
    text-align: center;
}

.link:hover {
    background: #185400;
    /* padding: 30px; */
    /* width: 30px; */
    /* transform: scale(1.1); */
    transition: ease-in-out 430ms;
    color: aquamarine;
}

@media only screen and (max-width: 600px) {
    .container {
        margin: 0;
        border: 0;
        border-radius: 0;
        width: 60vw;
        position: absolute;
        z-index: 1;
        height: 100vh;
        padding: 0;
        justify-content: start;
        align-items: start;
        padding: 20px;
    }
}